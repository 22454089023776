<template>
    <div style="width: 100%; overflow: hidden; position: relative;min-height: 600px" id="parent">
        <div class="right_content_parent">
            <div class="right_content">
                <div v-show="nowIndex == 1" style="height: auto;padding-bottom: 20px;">
                    <div style="font-size: 20px;color: #333;text-align: center">软件企业评测系统</div>
                    <div style="font-size: 14px;color: #FFA004;margin: 12px 0 30px;text-align: center">（为保证评测结果精准，请详细填写并保证数据真实有效）</div>
                    <div class="step-item">
                        <Steps :current="current">
                            <Step title="填写基本信息"></Step>
                            <Step title="提交生成测评报告"></Step>
                        </Steps>
                    </div>
                    <div v-show="current == 0">
                        <div class="step-title" style="margin-top: 0">企业基本信息</div>
                        <div class="step-bg">
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        企业注册地
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div">
                                        <RadioGroup v-model="baseForm.placeOfRegistration">
                                            <Radio label="1">中国境内</Radio>
                                            <Radio label="0">境外</Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        企业类型
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div">
                                        <RadioGroup v-model="baseForm.enterpriseType">
                                            <Radio label="1">独立法人</Radio>
                                            <Radio label="0">非独立法人</Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        主营业务范围为软件开发生产、系统集成、应用服务和其他相应技术服务
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div">
                                        <RadioGroup v-model="baseForm.isMbs">
                                            <Radio label="1">是</Radio>
                                            <Radio label="0">否</Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div class="step-title">上一年度研发能力</div>
                        <div class="step-bg">
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        研发费用总额/中国境内发生的研究开发费用
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div2">
                                        <Input type="number" v-model="baseForm.rdExpenses" placeholder='上一年度研发费用总额'/>
                                        <span style="margin: 0 10px">万元</span><span style="margin-right:10px">/</span>
                                        <Input type="number" v-model="baseForm.cnrdExpenses" placeholder='中国境内发生的研究开发费用'/>
                                        <span style="margin-left:10px;">万元</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        大专以上学历职工人数
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div">
                                        <Input type="number" v-model="baseForm.numberOfStaff"
                                               placeholder='上一年度大专以上学历职工人数（以签订劳动合同为准）'/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        大专以上研发人员人数
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div">
                                        <Input type="number" v-model="baseForm.numberOfRd"
                                               placeholder='上一年度大专以上研发人员人数'/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        月平均职工总人数
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div">
                                        <Input type="number" v-model="baseForm.workForce" placeholder='上一年度月平均职工总人数'/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div class="step-title">上一年度业务收入占比</div>
                        <div class="step-bg">
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        上一年度销售收入(万元)
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div">
                                        <Input type="number" v-model="baseForm.salesRevenue" placeholder='请输入上一年度销售收入'/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div table_div_left">
                                        销售收入类型（三选一）
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div">
                                        <Select v-model="baseForm.salesRevenueType" :transfer="transfer"
                                                style="width: 60%!important;"
                                                placeholder="请选择销售收入类型">
                                            <Option v-for="item in selectModal" :value="item.value" :key="item.value">
                                                {{item.label }}
                                            </Option>
                                        </Select>
                                    </div>
                                </Col>
                            </Row>
                            <Row v-if="baseForm.salesRevenueType!==''">
                                <Col span="10" class="table_div_three_left">
                                    {{selectModal[baseForm.salesRevenueType-1].label}}
                                </Col>
                                <Col span="14">
                                    <div class="table_div" style="border-bottom: 1px solid #dae2e5;border-left: 1px solid #dae2e5">
                                        <Input type="number" v-model="baseForm.salesDevelopment"
                                               :placeholder='selectModal[baseForm.salesRevenueType-1].label' />
                                        <span style="margin: 0 10px">万元</span>
                                    </div>
                                    <div class="table_div" style="border-left: 1px solid #dae2e5">
                                        <span style="margin: 0 10px">其中</span>
                                        <Input type="number" v-model="baseForm.libertySalesDevelopment" placeholder='自主开发销售（营业）收入' />
                                        <span style="margin-left:16px;">万元</span>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div class="step-title">产品和服务</div>
                        <div class="step-bg">
                            <Row>
                                <Col span="5" class="table_div_three_left">
                                    软件产品
                                </Col>
                                <Col span="9">
                                    <div class="table_div table_div_three_center">
                                        是否具有该产品的知识产权
                                    </div>
                                    <div class="table_div table_div_three_center">
                                        是否经软件检测机构检测合格，并出具合格报告
                                    </div>
                                    <div class="table_div table_div_three_center">
                                        是否符合法律、行政法规规定的其他条件
                                    </div>
                                </Col>
                                <Col span="10">
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.isIp">
                                            <Radio label="1">是</Radio>
                                            <Radio label="0">否</Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.isAt">
                                            <Radio label="1">是</Radio>
                                            <Radio label="0">否</Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.isLaw">
                                            <Radio label="1">是</Radio>
                                            <Radio label="0">否</Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="5" class="table_div_three_left">
                                    信息技术服务
                                </Col>
                                <Col span="9">
                                    <div class="table_div table_div_three_center">
                                        是否有承接信息技术服务的相关资质或标准
                                    </div>
                                    <div class="table_div table_div_three_center">
                                        是否具备提供技术服务的软硬件设施
                                    </div>
                                    <div class="table_div table_div_three_center">
                                        是否有承接信息技术服务的相关资质人员
                                    </div>
                                    <div class="table_div table_div_three_center">
                                        是否符合法律、行政法规规定的其他条件
                                    </div>
                                </Col>
                                <Col span="10">
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.isRq">
                                            <Radio label="1">是</Radio>
                                            <Radio label="0">否</Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.isHasf">
                                            <Radio label="1">是</Radio>
                                            <Radio label="0">否</Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.isRqp">
                                            <Radio label="1">是</Radio>
                                            <Radio label="0">否</Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.isLawoc">
                                            <Radio label="1">是</Radio>
                                            <Radio label="0">否</Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div class="step-title">企业质量体系（信息技术服务）</div>
                        <div class="step-bg">
                            <Row>
                                <Col span="5" class="table_div_three_left">
                                    质量体系
                                </Col>
                                <Col span="9">
                                    <div class="table_div table_div_three_center">
                                        ISO9000系列质量管理系统证书
                                    </div>
                                    <div class="table_div table_div_three_center">
                                        CMM/CMMI质量管理体系证书
                                    </div>
                                    <div class="table_div table_div_three_center">
                                        系统集成企业资质
                                    </div>
                                    <div class="table_div table_div_three_center">
                                        其他资质认定
                                    </div>
                                </Col>
                                <Col span="10">
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.iso">
                                            <Radio label="1">未进行</Radio>
                                            <Radio label="2">申报中</Radio>
                                            <Radio label="3">已通过</Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.cmm">
                                            <Radio label="1">未进行</Radio>
                                            <Radio label="2">申报中</Radio>
                                            <Radio label="3">已通过</Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.qualification">
                                            <Radio label="1">未进行</Radio>
                                            <Radio label="2">申报中</Radio>
                                            <Radio label="3">已通过</Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="table_div table_div_three_right">
                                        <RadioGroup v-model="baseForm.otherQualification ">
                                            <Radio label="1">未进行</Radio>
                                            <Radio label="2">申报中</Radio>
                                            <Radio label="3">已通过</Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div style="height: 180px; border-top: none; margin-top: 50px"
                             class="next_style common_liucheng;">
                            <div style="height: 40px; width: 120px" class="button_style" @click="submitForm()">
                                提交
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="nowIndex == 0" style="height: auto">
                    <div class="report-title">
                        <span>{{ reportList.userName }}</span>&nbsp;&nbsp;
                        <span style="margin-left: 20px">软件企业检测报告
                        <span>({{ reportList.createTime }})</span>
                        </span>
                    </div>
                    <div style="height:auto; border: 1px solid rgb(218, 226, 229)">
                        <div style="padding-top: 32px" class="div_center">
                            <Row :gutter="42">
                                <Col span="12">
                                    <div style="height: 254px;border:1px solid #dae2e5">
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业基本条件</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <div class="table_div">
                                                    <span v-if="checkstatus(['placeOfRegistration','enterpriseType','isMbs'])=='符合'" style="color:#26CA83">符合</span>
                                                    <span v-else style="color:#FF6160">不符合</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业研发能力</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <span v-if="checkstatus(['rdExpenses','cnrdExpenses','numberOfStaff','numberOfRd'])=='符合'" style="color:#26CA83">符合</span>
                                                <span v-else style="color:#FF6160">不符合</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业业务收入占比</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <span v-if="checkstatus(['salesDevelopment','libertySalesDevelopment'])=='符合'" style="color:#26CA83">符合</span>
                                                <span v-else style="color:#FF6160">不符合</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业产品和服务</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <span v-if="checkstatus(['isLaw','isAt','isLawoc','isRq','isHasf','isLawoc','isRqp'])=='符合'" style="color:#26CA83">符合</span>
                                                <span v-else style="color:#FF6160">不符合</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业质量体系</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <span v-if="checkonlystatus(['iso','cmm','qualification','otherQualification'])=='符合'" style="color:#26CA83">符合</span>
                                                <span v-else style="color:#FF6160">不符合</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col span="12">
                                    <div
                                            style="font-size: 14px;height: 254px;border: 1px solid #dae2e5;border-right: none;border-right: none;"
                                    >
                                        <Row>
                                            <Col span="6">
                                                <div class="table-box" style="height: 253px;line-height: 253px">
                                                    测评总得分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box-double" style="height: 254px;">
                                                    <div style="color: #FDA736;font-size: 14px;margin-top: 70px;font-weight: bold"> 您的企业测评结果
                                                        <b style="font-size: 32px" v-if="reportList.evalResult">符合</b>
                                                        <b style="font-size: 32px;color: #FF6160" v-else>不符合</b>
                                                    </div>
                                                    <div style="height: 42px;background: #F3F9FC;border-radius: 2px;
                                                    text-align: center;line-height: 42px;font-size: 12px;margin-top: 20px;border-bottom: ">
                                                        <div v-if="!reportList.evalResult ">
                                                            <Icon type="md-close-circle" color="#FF6160"/>
                                                            很遗憾，企业不符合软件企业申报标准
                                                        </div>
                                                        <div v-else>
                                                            <Icon type="md-checkmark-circle" color="#36C2CF"/>
                                                            恭喜，企业各项指标数据良好，符合软件企业申报标准
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="padding: 50px 0" class="div_center">
                            <div style="font-size: 14px;border: 1px solid #dae2e5;border-right: none;">
                                <Row>
                                    <Col span="5" class="table_div" style="border-right: 1px solid #dae2e5;">项目</Col>
                                    <Col span="7" class="table_div table_div_five_center">项目内容</Col>
                                    <Col span="4" class="table_div table_div_five_center">企业情况</Col>
                                    <Col span="4" class="table_div table_div_five_center">认定标准</Col>
                                    <Col span="4" class="table_div table_div_five_center">结果</Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left yi_hang_sheng">
                                        基本条件
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            企业注册地
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            企业类型
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            主营业务范围：软件开发生产、系统集成、应用服务和其他相应技术服务
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.placeOfRegistration)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.enterpriseType)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isMbs)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.placeOfRegistration)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.enterpriseType )[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isMbs)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.placeOfRegistration)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.enterpriseType)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isMbs)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        研发能力
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            总研发占比
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            中国境内研发占比
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            大专以上职工占比
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            大专以上研发人员占比
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.rdExpenses)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.cnrdExpenses)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.numberOfStaff)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.numberOfRd )[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.rdExpenses)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.cnrdExpenses )[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.numberOfStaff)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.numberOfRd )[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.rdExpenses)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.cnrdExpenses)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.numberOfStaff)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.numberOfRd)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        业务收入占比
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            {{reportList.salesRevenueType>0?selectModal[reportList.salesRevenueType-1]['label'] + '占比':''}}
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            自主开发销售（营业）收入占比
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.salesDevelopment)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.libertySalesDevelopment)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.salesDevelopment)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.libertySalesDevelopment)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.salesDevelopment)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.libertySalesDevelopment)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        软件产品
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否具有该产品的知识产权
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否经软件检测机构检测合格，并出具合格报告
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否符合法律、行政法规规定的其他条件
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLaw)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isAt )[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLawoc )[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLaw)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isAt )[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLawoc)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isLaw)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isAt)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isLawoc)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        信息技术服务
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否有承接信息技术服务的相关资质或标准
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否具备提供技术服务的软硬件设施
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否有承接信息技术服务的相关资质人员
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否符合法律、行政法规规定的其他条件
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isRq)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isHasf)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLawoc )[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isRqp)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isRq)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isHasf)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLawoc)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isRqp)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isRq)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isHasf)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isLawoc)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isRqp)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        质量体系
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            ISO9000系列质量管理系统证书
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            CMM/CMMI质量管理体系证书
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            系统集成企业资质
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            其他资质认定
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.iso)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.cmm)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.qualification)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.otherQualification)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.iso)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.cmm)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.qualification)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.otherQualification)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4" style="display: flex;align-items: center;justify-content: center;border-right: 1px solid #dae2e5">
                                            <span v-if="checkonlystatus(['iso','cmm','qualification','otherQualification'])=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div style="height: 180px; border-top: none" class="next_style common_liucheng;">
                            <div style="height: 40px; width: 120px;display: none" class="button_style">下载报告</div>
                            <div @click="reTest()" style="height: 40px;width: 120px;margin-left: 20px;background-color:#1890FF;" class="button_style">重新测评
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import $ from "jquery";
    import {
        getEnterpriseInfo
    } from "@/plugins/api/EnterpriseExam";
    import {
        confirmBase,
        getReportInfo,
    } from "@/plugins/api/EnterpriseSoftExam";
    import {
        isNumber,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";


    export default {
        name: "EnterpriseScienceExamination",
        mounted() {
            // this.getReportFun()
            this.getUserInfo()
        },
        computed: {
            ...mapGetters({
                userName: "userStore/userName",
                userId: "userStore/userId",
            }),
        },
        data() {
            return {
                evalId: "",
                transfer: true,
                reportList: [],
                selectModal: [
                    {value: '1', label: '软件产品开发销售（营业）收入'},
                    {value: '2', label: '嵌入式软件产品开发销售（营业）收入'},
                    {value: '3', label: '信息系统集成软件产品开发销售（营业）收入'}
                ],
                userInfo: [],
                // 第一步from
                baseForm: {
                    "cmm": "",//CMM/CMMI质量管理体系证书(1.未进行 2.申报中 3.已通过) ,
                    "cnrdExpenses": "",//中国境内发生的研究开发费用
                    "enterpriseType": "",// 企业类型
                    "isAt": "",//是否经软件检测机构检测合格，并出具合格报告 ,
                    "isHasf": "",//是否具备提供技术服务的软硬件设施 ,
                    "isIp": "",//是否具有该产品的知识产权(0.否，1.是) ,
                    "isLaw": "",//是否符合法律、行政法规规定的其他条件 ,
                    "isLawoc": "",//是否符合法律、行政法规规定的其他条件 ,
                    "isMbs": "",//主营业务范围 ,
                    "isRq": "",//是否有承接信息技术服务的相关资质或标准 ,
                    "isRqp": "",//是否有承接信息技术服务的相关资质人员 ,
                    "iso": "",//ISO9000系列质量管理系统证书(1.未进行 2.申报中 3.已通过) ,
                    "libertySalesDevelopment": "",// 自主开发销售（营业）收入 ,
                    "numberOfRd": "",//大专以上研发人员人数 ,
                    "numberOfStaff": "",//大专以上学历职工人数 ,
                    "otherQualification": "",//其他资质认定(1.未进行 2.申报中 3.已通过) ,
                    "placeOfRegistration": "",//企业注册地(用;分割，一共四部分，1.企业注册地 2.企业情况 3.认定标准 4.结果)例：1;境内;境内;符合 ,
                    "qualification": "",//系统集成企业资质(1.未进行 2.申报中 3.已通过) ,
                    "rdExpenses": "",//研发费用总额 ,
                    "salesDevelopment": "",//开发销售（营业）收入 ,
                    "salesRevenue": "",//上一年度销售收入 ,
                    "salesRevenueType": "",//销售收入类型(1.软件产品开发销售（营业）收入 2.嵌入式软件产品开发销售(营业)收入 3.信息系统集成产品开发销售（营业）收入) ,
                    "status": "1",//测评状态（0.暂存，1.完成） ,
                    "workForce": ""//月平均职工总人数
                },
                nowIndex: 0,
                current: 0,
                tab_height: 0,
                tab_list: [
                    {name: "国家高新技术企业体检", path: "enterprise-physical-examination", ischoose: false},
                    {name: "科技型中小企业测评", path: "enterprise-science-examination", ischoose: false},
                    {name: "软件企业测评", path: "enterprise-soft-examination", ischoose: true}
                ]
            };
        },
        methods: {
            async getUserInfo() {
                getEnterpriseInfo().then((res) => {
                    if (res.code == 200) {
                        this.userInfo = res.data
                        this.getReportFun()
                    }
                });
            },
            // 得到报告
            async getReportFun() {
                let param = {
                    id: 0,
                    status: '1',
                    userName: this.userInfo.enterpriseName
                }
                const json = await getReportInfo(param)
                if (json.code == 200 && json.data.list && json.data.list.length > 0) {
                    if (json.data.list[0].status == 1) {
                        this.nowIndex = 0
                        this.reportList = json.data.list[0]
                        this.reportList.createTime = this.reportList.createTime.split('T')[0]
                        // this.showReport()
                    } else {
                        this.reportList = json.data.list[0]
                        this.reportList.createTime = this.reportList.createTime.split('T')[0]
                        for (let item in this.baseForm) {
                            this.baseForm[item] = json.data.list[0][item]
                        }
                        if (this.baseForm.placeOfRegistration) {
                            this.provinceName = this.baseForm.placeOfRegistration.substr(0, 3)
                            this.cityName = this.baseForm.placeOfRegistration.substring(3)
                            console.log(this.provinceName, this.cityName)
                        }
                    }
                } else {
                    this.reTest()
                }
                let date = new Date();
                this.thisYear = date.getFullYear();
            },
            async submitForm() {
                for (let item in this.baseForm) {
                    if (this.baseForm[item] === '') {
                        warnToast("请将数据填写完成");
                        return;
                    }
                }
                let json = await confirmBase(this.baseForm);
                if (json.code == "200") {
                    this.nowIndex = 0;
                    this.reportList = json.data
                    this.reportList.createTime = this.reportList.createTime.split('T')[0]
                    this.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message)
                }
            },
            reTest() {
                this.nowIndex = 1;
                this.current = 0;
                this.changeHeight();
                this.baseForm = {
                    "cmm": "",
                    "cnrdExpenses": "",
                    "enterpriseType": "",
                    "isAt": "",
                    "isHasf": "",
                    "isIp": "",
                    "isLaw": "",
                    "isLawoc": "",
                    "isMbs": "",
                    "isRq": "",
                    "isRqp": "",
                    "iso": "",
                    "libertySalesDevelopment": "",
                    "numberOfRd": "",
                    "numberOfStaff": "",
                    "otherQualification": "",
                    "placeOfRegistration": "",
                    "qualification": "",
                    "rdExpenses": "",
                    "salesDevelopment": "",
                    "salesRevenue": "",
                    "salesRevenueType": "",
                    "status": "1",
                    "workForce": ""
                }
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0

                let date = new Date();
                this.thisYear = date.getFullYear();
            },
            pre() {
                this.current -= 1;
            },
            showTab(index) {
                this.$router.push({
                    path: this.tab_list[index].path
                })
                // this.changeHeight();
            },
            changeHeight() {
                let that = this;
                this.$nextTick(() => {
                    that.tab_height =
                        Number($(".right_content_parent").height() + 190) -
                        (Number(document.body.clientHeight) - 101) >
                        20
                            ? $(".right_content_parent").height() + 200 + "px"
                            : Number(document.body.clientHeight) - 101 + "px";
                });
            },
            datasplit(arr) {
                if (arr) {
                    return arr.split(';')
                } else {
                    return ''
                }
            },
            checkstatus(obj){
                for(let m of obj){
                    if(this.datasplit(this.reportList[m])[3] != '符合'){
                        return '不符合'
                    }
                }
                return '符合'
            },
            checkonlystatus(obj){
                for(let m of obj){
                    if(this.datasplit(this.reportList[m])[3] == '符合'){
                        return '符合'
                    }
                }
                return '不符合'
            }
        },
    };
</script>

<style scoped lang="scss">
    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }
    // .table_div.table_div_three_center.yi_hang_sheng {
    //     border-left: 1px solid #dae2e5;
    // }

    .table_div {
        height: 50px;
        text-align: center;
        display: flex;
        white-space: normal;
        align-items: center;
        width: 100%;
        justify-content: center;
    }


    .table_div2 {
        height: 50px;
        line-height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .table_div_left {
        border-right: 1px solid #dae2e5;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
    }

    .table_div_three_center {
        border-right: 1px solid #dae2e5;
        border-bottom: 1px solid #dae2e5;
        //border-left: 1px solid #dae2e5;
    }

    .table_div_three_center:last-child {
        border-bottom: none;
    }

    .table_div_three_right {
        border-bottom: 1px solid #dae2e5;
    }

    .table_div_three_right:last-child {
        border-bottom: none;
    }

    .table_div_three_left {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #dae2e5;
    }
    .table_div_five_center{
        border-right: 1px solid #dae2e5;
    }

    .table-box {
        width: 100%;
        height: 55px;
        // background-color: #f3fcfc;
        line-height: 55px;
        text-align: center;
        border-bottom: 1px solid #dae2e5;
        border-right: 1px solid #dae2e5;
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .ivu-progress {
        display: inline-block !important;
        width: 65% !important;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button_style {
        display: inline-block;
        border-radius: 2px;
        background-color: #1890FF;
        width: 60px;
        height: 40px;
        z-index: 51;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
    }

    .retest-btn {
        height: 40px;
        width: 120px;
        margin-left: 20px;
        background-color: #1890ff;
    }

    .isChoose {
        color: #37c5cf;
    }

    .right_content {
        width: 100%;
        /*border-width: 1px;*/
        /*border-color: rgb(218, 226, 229);*/
        /*border-style: solid;*/
        background-color: rgb(255, 255, 255);
        min-width: 900px;
        height: auto;
    }

    .right_content_parent {
        box-sizing: border-box;
        width: 100%;
        min-width: 960px;
        display: flex;
        justify-content: center;
        padding-top: 50px;
        position: relative;
        margin-bottom: 130px;
    }

    .left_tab_parent {
        float: left;
        min-width: 240px;
        width: 20%;
        background-color: rgb(250, 250, 252);

        .left_tab {
            margin-top: 60px;

            div {
                width: 210px;
                margin: 0 auto;
                height: 48px;
                line-height: 48px;
                font-size: 14px;
                box-sizing: border-box;
                padding-left: 31px;
                margin-bottom: 20px;
                border-width: 1px;
                border-color: rgb(235, 235, 235);
                border-style: solid;
                background-color: rgb(255, 255, 255);
                cursor: pointer;
            }
        }
    }

    /deep/ .demo-table-info-column {
        background-color: #f3fcfc;
        color: white;
    }

    /deep/ .ivu-table-tbody .demo-table-info-column {
        color: black !important;
    }
    /deep/ .ivu-input-wrapper {
        width: auto;
    }

    /deep/ .ivu-input {
        height: 32px !important;
        background: #FFFFFF;
        border-radius: 4px !important;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0 10px;
    }
    /deep/ .ivu-select-selection {
        border-radius: 4px !important;
    }

    .hidden_span {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        white-space: pre-wrap;
        line-height: normal;
        margin-left: 10px;
        color: #333333;
    }

    .step-item {
        height: 100px;
        margin: 60px 0 20px 50%;
        width: 80%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
    }

    .step-bg {
        width: 90%;
        margin-left: 50%;
        transform: translateX(-50%);
        border: 1px solid #dae2e5;
    }

    .step-title {
        width: 90%;
        transform: translateX(-50%);
        font-size: 16px;
        color: #333333;
        margin: 24px 0 24px 50%;
        font-weight: bold;
    }

    .ivu-row {
        border-bottom: 1px solid #dae2e5;
    }

    .ivu-row:last-child {
        border-bottom: none;
    }

    .ivu-radio-wrapper {
        margin-right: 10px;
        text-align: left;
    }

    .table-box2 {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid #dae2e5;
        border-right: 1px solid #dae2e5;
        font-size: 14px;
    }

    .table-box-double {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        /*border-bottom: 1px solid #dae2e5;*/
        border-right: 1px solid #dae2e5;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
    }

    .report-title{
        display: flex;
        justify-content: center;
        height: 70px;
        line-height: 70px;
        // background-color: rgb(54, 194, 207);
        background-color: rgb(24, 144, 255);
        border: 1px solid rgb(24, 144, 255);
        color: white;
        border-bottom: none;
        font-size: 20px;
    }
    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        /*border-bottom: 2px dotted rgb(218, 226, 229);*/
    }
</style>
